import useQianKunEventCenter from '@/hooks/useQianKunEventCenter'
import useQianKun from '@/hooks/useQianKun'
import {
	difficultyType,
	DifficultyType,
	questionType,
	QuestionTypeOptions,
	SheetSongType
} from './poolConfig'
import { Modal, ModalProps } from 'ant-design-vue'
import { createVNode, getCurrentInstance, render } from 'vue'
import TabTablePool from '../TabTablePool.vue'
import MaterialLibrary from '@/views/Editor/CanvasTool/materialLibraryPool/index.vue'
import _ from 'lodash'
import useFetchApi from '@/hooks/useFetchApi'
import useShowMessage from '@/hooks/useShowMessage'

export interface ConfigModalProps {
	title: string
	filter?: Array<{
		name: string
		value: string
		component: any
		componentProps: any
	}>
	defaultFilter?: Record<string, any>
	tabTables: Array<{
		name: string
		key: string
		api: string
		afterGetData?: (data: any) => void
		columns: Array<any>
		defaultFilter?: Record<string, any>
		breadcrumbDefault?: Record<string, any>
		breadcrumbFilterMap?: Record<string, any>
		actions?: Array<{
			name: string
			actionsFunction: (column: any) => void
		}>
	}>
}

export default () => {
	const { fetchApi } = useFetchApi()
	const { isMicroApp, document: qianKunDocument } = useQianKun()
	const appContext = getCurrentInstance()!.appContext
	const {
		openQGroupDialog,
		openEnjoySongDialog,
		openSingPlayDialog,
		openMusicRhythmDialog,
		openQuestionsBankDialog
	} = useQianKunEventCenter()
	const { showMeassage } = useShowMessage()

	const openComponentModal = (
		comp: any,
		compProps?: Record<string, any>,
		modalOptions?: ModalProps
	) => {
		return new Promise((resolve, reject) => {
			try {
				const container = document.createElement('div')
				const destroy = () => {
					render(null, container)
					container.remove()
				}
				const vNode = createVNode(
					Modal,
					{
						visible: true,
						appContext: appContext,
						footer: null,
						width: 1000,
						title: compProps?.title,
						destroyOnClose: true,
						getContainer: () => {
							if (isMicroApp) {
								return qianKunDocument.body
							}
							return document.body
						},
						onCancel: () => {
							destroy()
							reject('config modal cancelled')
						},
						style: {
							top: '120px'
						},
						...(modalOptions || {})
					},
					{
						default: () => [
							createVNode(comp, {
								...(compProps || {}),
								onConfirm: (data: any) => {
									destroy()
									resolve(data)
								}
							})
						]
					}
				)
				vNode.appContext = appContext
				render(vNode, container)

				document.body.appendChild(container)
			} catch (e) {
				reject(e)
			}
		})
	}

	const openConfigModal = (
		config: ConfigModalProps,
		modalOptions?: ModalProps
	) => {
		return new Promise<void>((resolve, reject) => {
			try {
				const _tabTables = config.tabTables.map((tab) => {
					tab.actions = [
						...(tab.actions || []),
						{
							name: '插入',
							actionsFunction: _.debounce((column: any) => {
								resolve(column)
								destroy()
							}, 300)
						}
					]
					return tab
				})
				config.tabTables = _tabTables
				const container = document.createElement('div')
				const destroy = () => {
					render(null, container)
					container.remove()
				}
				const vNode = createVNode(
					Modal,
					{
						visible: true,
						appContext: appContext,
						footer: null,
						width: 1000,
						title: config.title,
						destroyOnClose: true,
						onCancel: () => {
							destroy()
							reject('config modal cancelled')
						},
						style: {
							top: '120px'
						},
						...(modalOptions || {})
					},
					{
						default: () => [
							createVNode(TabTablePool, {
								...config
							})
						]
					}
				)
				vNode.appContext = appContext
				render(vNode, container)
				document.body.appendChild(container)
			} catch (e) {
				reject(e)
			}
		})
	}

	const filterBookSectionColumn = {
		title: '所属教材章节',
		dataIndex: 'book.section',
		key: 'book_id',
		subKey: 'book_section_id',
		visible: false,
		filter: {
			comp: 'multi-select-tree',
			compOption: {
				title: '请选择教材章节',
				modalTitle: '选择教材章节',
				api: {
					url: (attrs: any) => {
						const { activeTabKey } = attrs
						return activeTabKey === 'official'
							? `https://${process.env.VUE_APP_USER_API_HOST}/teacher/resource/official/books`
							: `https://${process.env.VUE_APP_USER_API_HOST}/teacher/resource/teacher/books`
					},
					params: {
						name: '{keyword}'
					},
					matchKey: {
						label: '{press_version.name}-{name}',
						value: '{id}'
					}
				},
				subApi: {
					url: (attrs: any) => {
						const { activeTabKey } = attrs
						return activeTabKey === 'official'
							? `https://${process.env.VUE_APP_USER_API_HOST}/teacher/resource/official/book/{id}/sections`
							: `https://${process.env.VUE_APP_USER_API_HOST}/teacher/resource/teacher/book/{id}/sections`
					},
					matchKey: {
						key: '{id}',
						title: '{name}',
						value: '{id}'
					}
				}
			}
		}
	}

	/** 打开音乐律动的选择弹窗 */
	const openMusicRhythmModal = () => {
		if (isMicroApp) {
			return openMusicRhythmDialog()
		}
		const columns = [
			{
				title: '歌曲名称',
				dataIndex: 'name',
				key: 'name',
				ellipsis: true,
				width: 80,
				filter: {
					comp: 'input'
				}
			},
			{
				title: '创建日期',
				dataIndex: 'created_at',
				key: 'created',
				width: 80
			},
			{
				title: '更新日期',
				dataIndex: 'updated_at',
				key: 'updated',
				width: 80
			},
			{
				title: '操作',
				key: 'action',
				width: 30,
				slots: { customRender: 'actions' }
			}
		]
		return openConfigModal({
			title: '音乐律动',
			tabTables: [
				{
					name: '律动广场',
					key: 'official',
					defaultFilter: {
						type: 2
					},
					api: `https://${process.env.VUE_APP_USER_API_HOST}/teacher/userMelodyFiles`,
					columns: columns
				},
				{
					name: '我的音乐律动',
					key: 'teacher',
					defaultFilter: {
						type: 1
					},
					api: `https://${process.env.VUE_APP_USER_API_HOST}/teacher/userMelodyFiles`,
					columns: columns
				}
			]
		}).then((record: any) => {
			return new Promise<void>((resolve) => {
				const data: any = {
					sid: record.id,
					source: record.source
				}
				if (record.source === 'teacher') {
					fetch(record.json_file_url)
						.then((res) => res.json())
						.then((jsonData) => {
							data.meta = {
								...record,
								json_file_url: jsonData,
								background_url: jsonData.background_url,
								border_heightLight_url: jsonData.border_heightLight_url,
								border_url: jsonData.border_url
							}
							resolve(data)
						})

					return
				}
				resolve(data)
			})
		})
	}

	/** 打开曲谱的选择弹窗 */
	const openSingPlayModal = () => {
		if (isMicroApp) {
			return openSingPlayDialog()
		}
		const officialColumns = [
			{
				title: '歌曲名称',
				dataIndex: 'name',
				key: 'name',
				ellipsis: true,
				width: 80,
				filter: {
					comp: 'input'
				}
			},
			{
				title: '歌曲编码',
				dataIndex: 'song_code',
				key: 'song_code',
				width: 80,
				ellipsis: true
			},
			{
				title: '歌曲简拼',
				dataIndex: 'py',
				key: 'py',
				ellipsis: true,
				width: 80
			},
			{
				title: '歌曲类型',
				dataIndex: 'song_type_text',
				key: 'song_type_text',
				width: 80
			},
			{
				title: '标签',
				dataIndex: 'tags',
				key: 'tags',
				slots: { customRender: 'tags' },
				width: 100,
				ellipsis: true
			},
			{
				title: '关联教材',
				dataIndex: 'book.name',
				key: 'book.name',
				ellipsis: true,
				width: 80
			},
			{
				...filterBookSectionColumn
			},
			{
				title: '教材出版社',
				dataIndex: 'book.press_version.name',
				ellipsis: true,
				width: 80
			},
			{
				title: '关联教材章节',
				dataIndex: 'section.name',
				key: 'book.name',
				ellipsis: true,
				width: 80
			},
			{
				title: '操作',
				key: 'action',
				fixed: 'right',
				width: 80,
				slots: { customRender: 'actions' }
			}
		]
		const teacherColumns = [
			{
				title: '歌曲名称',
				dataIndex: 'name',
				key: 'name',
				ellipsis: true,
				width: 80,
				slots: { customRender: 'parent' },
				filter: {
					comp: 'input'
				}
			},
			{
				title: '更新时间',
				dataIndex: 'updated_at',
				key: 'updated_at',
				ellipsis: true,
				width: 80
			},
			{
				title: '操作',
				key: 'action',
				fixed: 'right',
				width: 80,
				slots: { customRender: 'actions' }
			}
		]

		return openConfigModal({
			title: '曲谱',
			tabTables: [
				{
					name: '曲谱广场',
					key: 'official',
					api: `https://${process.env.VUE_APP_USER_API_HOST}/teacher/resource/official/sheet-songs`,
					defaultFilter: {
						except_statuses: [2],
						except_song_types: [4]
					},
					afterGetData: (dataSource) => {
						return dataSource.map((data: any) => {
							data.song_type_text = SheetSongType[data.song_type]
							return data
						})
					},
					columns: officialColumns
				},
				{
					name: '我的曲谱',
					key: 'teacher',
					api: `https://${process.env.VUE_APP_RESOURCE_CENTER_HOST}/teacher/courseware-space-nodes`,
					afterGetData: (dataSource) => {
						dataSource = dataSource.map((data: any) => {
							data.isParent = data.type === 0
							data.name = data.name || '未命名曲谱'
							return data
						})
						return _.orderBy(
							dataSource,
							['type', 'updated_at'],
							['asc', 'desc']
						)
					},
					defaultFilter: {
						create_module: 1,
						courseware_types: ['sheet_song'],
						parent_id: 0
					},
					breadcrumbDefault: {
						key: 'parent_id',
						label: '< 我的曲谱',
						value: undefined,
						isDefault: true
					},
					breadcrumbFilterMap: {
						key: 'parent_id',
						label: 'name',
						value: 'id'
					},
					columns: teacherColumns
				}
			]
		}).then((record: any) => {
			return new Promise((resolve) => {
				if (record.courseware) {
					const { extend_data = {} } = record.courseware
					if (!extend_data.evxml_file_url) {
						showMeassage('没有曲谱内容，请先进行编创', 'warning')
						return
					}
					const data: any = {
						sid: record.courseware.id,
						source: record.source
					}
					data.meta = {
						...extend_data
					}
					resolve(data)
					return
				}
				resolve({
					sid: record.id,
					source: record.source
				})
			})
		})
	}

	/** 打开欣赏歌曲的选择弹窗 */
	const openEnjoySongModal = () => {
		if (isMicroApp) {
			return openEnjoySongDialog()
		}
		const columns = [
			{
				title: '歌曲名称',
				dataIndex: 'name',
				key: 'name',
				ellipsis: true,
				width: 80,
				filter: {
					comp: 'input'
				}
			},
			{
				title: '歌曲编码',
				dataIndex: 'song_code',
				key: 'song_code',
				width: 80,
				ellipsis: true
			},
			{
				title: '歌曲简拼',
				dataIndex: 'py',
				key: 'py',
				ellipsis: true,
				width: 80
			},
			{
				title: '标签',
				dataIndex: 'tags',
				key: 'tags',
				slots: {
					customRender: 'tags'
				},
				width: 100,
				ellipsis: true
			},
			{
				title: '关联教材',
				dataIndex: 'book.name',
				key: 'book.name',
				ellipsis: true,
				width: 80
			},
			{
				title: '教材出版社',
				dataIndex: 'book.press_version.name',
				ellipsis: true,
				width: 120
			},
			{
				...filterBookSectionColumn
			},
			{
				title: '关联教材章节',
				dataIndex: 'section.name',
				key: 'book.name',
				ellipsis: true,
				width: 120
			},
			{
				title: '操作',
				key: 'action',
				fixed: 'right',
				width: 80,
				slots: {
					customRender: 'actions'
				}
			}
		]
		return openConfigModal({
			title: '欣赏歌曲',
			defaultFilter: {
				except_statuses: [2]
			},
			tabTables: [
				{
					name: '官方歌曲',
					key: 'official',
					api: `https://${process.env.VUE_APP_USER_API_HOST}/teacher/resource/official/enjoy-songs`,
					columns: columns
				}
			]
		}).then((record: any) => {
			return {
				sid: record.id,
				source: record.source
			}
		})
	}

	/** 打开答题PK的选择弹窗 */
	const openPKQuestionsModal = () => {
		if (isMicroApp) {
			return openQGroupDialog()
		}
		const officialColumns = [
			{
				title: '题目名称',
				dataIndex: 'name',
				key: 'name',
				ellipsis: true,
				width: 80,
				filter: {
					comp: 'input'
				}
			},
			{
				title: '题目作者',
				dataIndex: 'author_name',
				key: 'author_name',
				width: 80,
				ellipsis: true
			},
			{
				title: '题目数量',
				dataIndex: 'extend_data.question_num',
				key: 'extend_data.question_num',
				ellipsis: true,
				width: 80
			},
			{
				title: '标签',
				dataIndex: 'tags',
				key: 'tags',
				slots: { customRender: 'tags' },
				width: 100,
				ellipsis: true
			},
			{
				title: '关联教材',
				dataIndex: 'book.name',
				key: 'book.name',
				ellipsis: true,
				width: 80
			},
			{
				...filterBookSectionColumn
			},
			{
				title: '教材出版社',
				dataIndex: 'book.press_version.name',
				ellipsis: true,
				width: 80
			},
			{
				title: '关联教材章节',
				dataIndex: 'section.name',
				key: 'book.name',
				ellipsis: true,
				width: 80
			},
			{
				title: '操作',
				key: 'action',
				fixed: 'right',
				width: 80,
				slots: { customRender: 'actions' }
			}
		]
		const teacherColumns = [
			{
				title: '题目名称',
				dataIndex: 'name',
				key: 'name',
				ellipsis: true,
				width: 80,
				slots: { customRender: 'parent' },
				filter: {
					comp: 'input'
				}
			},
			{
				title: '更新时间',
				dataIndex: 'updated_at',
				key: 'updated_at',
				ellipsis: true,
				width: 80
			},
			{
				title: '操作',
				key: 'action',
				fixed: 'right',
				width: 80,
				slots: { customRender: 'actions' }
			}
		]

		return openConfigModal({
			title: '题目组',
			tabTables: [
				{
					name: '我的题目组',
					key: 'teacher',
					api: `https://${process.env.VUE_APP_RESOURCE_CENTER_HOST}/teacher/courseware-space-nodes`,
					afterGetData: (dataSource) => {
						dataSource = dataSource.map((data: any) => {
							data.isParent = data.type === 0
							data.name = data.name || '未命名题目组'
							return data
						})
						return _.orderBy(
							dataSource,
							['type', 'updated_at'],
							['asc', 'desc']
						)
					},
					defaultFilter: {
						create_module: 2,
						courseware_types: ['qgroup'],
						parent_id: 0
					},
					breadcrumbDefault: {
						key: 'parent_id',
						label: '< 我的题目组',
						value: undefined,
						isDefault: true
					},
					breadcrumbFilterMap: {
						key: 'parent_id',
						label: 'name',
						value: 'id'
					},
					columns: teacherColumns
				},
				{
					name: '题目组广场',
					key: 'official',
					api: `https://${process.env.VUE_APP_RESOURCE_CENTER_HOST}/official/coursewares`,
					defaultFilter: {
						subject: 'music',
						name: '',
						type: 'qgroup',
						teaching_system: 'music',
						status: 1
					},
					columns: officialColumns
				}
			]
		}).then((record: any) => {
			return new Promise((resolve) => {
				if (record.courseware) {
					const { extend_data = {} } = record.courseware
					if (!extend_data.question_num) {
						showMeassage('没有题目内容，请先进行创建', 'warning')
						return
					}
					const data: any = {
						sid: record.courseware.id,
						source: record.source
					}
					data.meta = {
						questionGroupName: record?.name,
						...extend_data
					}
					resolve(data)
				}
				resolve({
					sid: record?.id,
					source: record?.source,
					meta: {
						questionGroupName: record?.name,
						...(record?.extend_data || {})
					}
				})
			})
		})
	}

	/** 打开题库的选择弹窗 */
	const openQuestionsBankModal = () => {
		if (isMicroApp) {
			return openQuestionsBankDialog()
		}
		const columns = [
			{
				title: '题目编号',
				dataIndex: 'id',
				key: 'id',
				ellipsis: true,
				width: 40
			},
			{
				title: '题型',
				dataIndex: 'type_text',
				key: 'type_text',
				ellipsis: true,
				width: 40
			},
			{
				title: '题型',
				dataIndex: 'type',
				key: 'type',
				ellipsis: true,
				width: 40,
				visible: false,
				filter: {
					comp: 'select',
					compOption: {
						allowClear: true,
						options: QuestionTypeOptions
					}
				}
			},
			{
				title: '摘要',
				dataIndex: 'summary',
				key: 'summary',
				width: 80,
				ellipsis: true,
				filter: {
					comp: 'input'
				}
			},
			{
				title: '标签',
				dataIndex: 'tags',
				key: 'tags',
				width: 80,
				ellipsis: true,
				filter: {
					comp: 'select',
					compOption: {
						allowClear: true,
						showSearch: true,
						placeholder: '请选择标签',
						defaultActiveFirstOption: false,
						filterOption: false,
						noFoundContent: null,
						options: [],
						selectSearch: (
							keyword: string,
							formData: any,
							filter: any,
							activeTabKey: string
						) => {
							fetchApi({
								url:
									activeTabKey === 'official'
										? `https://${process.env.VUE_APP_EXAM_CENTER_HOST}/official/tags`
										: `https://${process.env.VUE_APP_EXAM_CENTER_HOST}/school/tags`,
								method: 'GET',
								params: {
									keyword,
									per_page: 50,
									current: 1
								}
							}).then((res) => {
								const data = res.data.data.items || []
								filter.compOption.options = data.map((i: any) => ({
									label: i.tag,
									value: i.tag
								}))
							})
						}
					}
				}
			},
			{
				title: '难度',
				dataIndex: 'level_text',
				key: 'level_text',
				width: 80,
				ellipsis: true,
				align: 'center',
				slots: { customRender: 'tags_color' }
			},
			{
				title: '难度',
				dataIndex: 'level',
				key: 'level',
				width: 80,
				ellipsis: true,
				align: 'center',
				visible: false,
				filter: {
					comp: 'common-radio',
					compOption: {
						isCheckAll: true,
						allValue: undefined,
						options: DifficultyType
					}
				}
			},
			{
				title: '操作',
				key: 'action',
				fixed: 'right',
				width: 80,
				slots: { customRender: 'actions' }
			}
		]
		return openConfigModal({
			title: '题库',
			defaultFilter: {
				types: [1, 2, 3, 4, 8, 9, 10, 11, 12, 14]
			},
			tabTables: [
				{
					name: '平台题库',
					key: 'official',
					api: `https://${process.env.VUE_APP_EXAM_CENTER_HOST}/official/demo-questions`,
					afterGetData: (dataSource) => {
						return dataSource.map((data: any) => {
							data.type_text = questionType[data.type]
							data.level_text = difficultyType[data.level]
							data.level_text_color =
								data.level === 0 ? 'green' : data.level === 1 ? 'blue' : 'red'
							return data
						})
					},
					defaultFilter: {
						except_statuses: [2]
					},
					columns: columns
				},
				{
					name: '校端题库',
					key: 'teacher',
					api: `https://${process.env.VUE_APP_EXAM_CENTER_HOST}/school/demo-questions`,
					afterGetData: (dataSource) => {
						return dataSource.map((data: any) => {
							data.type_text = questionType[data.type]
							data.level_text = difficultyType[data.level]

							return data
						})
					},
					columns: columns
				}
			]
		}).then((record: any) => {
			return {
				sid: record.id,
				source: record.source
			}
		})
	}

	/** 打开素材库弹窗*/
	const openMaterialLibrary = () => {
		return openComponentModal(MaterialLibrary, {}, {
			bodyStyle: {
				padding: '10px 0'
			},
			width: 1200,
			title: '素材库'
		} as any)
	}

	return {
		openEnjoySongModal,
		openSingPlayModal,
		openMusicRhythmModal,
		openQuestionsBankModal,
		openMaterialLibrary,
		openComponentModal,
		openPKQuestionsModal
	}
}
