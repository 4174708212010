
import { computed, defineComponent, ref, watch } from 'vue'
import { MutationTypes, useStore } from '@/store'
import { PPTElement, Slide, SlideBackground, SlideTheme } from '@/types/slides'
import { PRESET_THEMES } from '@/configs/theme'
import { WEB_FONTS } from '@/configs/font'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

import ColorButton from './common/ColorButton.vue'
import useUpLoadResource from '@/hooks/useUpLoadResource'
import usePreviewImage from '@/hooks/usePreviewImage'
import { message, Modal } from 'ant-design-vue'
import logger from '@evideo/logger'
import usePageLoading from '@/hooks/usePageLoading'
import FontNameSelect from './common/FontNameSelect.vue'

const themes = PRESET_THEMES
const webFonts = WEB_FONTS

const viewportRatioMap = {
    0.5625: '16 : 9',
    0.75: '4 : 3',
    0.625: '16 : 10'
}

export default defineComponent({
    name: 'slide-design-panel',
    components: {
        ColorButton,
        Modal,
        FontNameSelect
    },
    setup() {
        const store = useStore()
        const slides = computed(() => store.state.slides)
        const theme = computed(() => store.state.theme)
        const availableFonts = computed(() => store.state.availableFonts)
        const viewportRatio = ref(store.state.viewportRatio)
        const currentSlide = computed<Slide>(() => store.getters.currentSlide)
        const viewportChangeModalVisible = ref(false)
        const newViewportRatio = ref(store.state.viewportRatio)

        const background = computed(() => {
            if (!currentSlide.value?.background) {
                return {
                    type: 'solid',
                    value: '#fff'
                } as SlideBackground
            }
            return currentSlide.value.background
        })

        const { addHistorySnapshot } = useHistorySnapshot()
        const { getImageResourceUrl } = useUpLoadResource()
        const { pptGeneratePreviewOneByOne } = usePreviewImage()
        const { showLoading, closeLoading } = usePageLoading()

        watch(
            () => store.state.viewportRatio,
            () => {
                viewportRatio.value = store.state.viewportRatio
                newViewportRatio.value = store.state.viewportRatio
            }
        )

        // 设置背景模式：纯色、图片、渐变色
        const updateBackgroundType = (type: 'solid' | 'image' | 'gradient') => {
            if (type === 'solid') {
                const newBackground: SlideBackground = {
                    ...background.value,
                    type: 'solid',
                    color: background.value.color || '#fff'
                }
                store.commit(MutationTypes.UPDATE_SLIDE, {
                    background: newBackground
                })
            } else if (type === 'image') {
                const newBackground: SlideBackground = {
                    ...background.value,
                    type: 'image',
                    image: background.value.image || '',
                    imageSize: background.value.imageSize || 'cover'
                }
                store.commit(MutationTypes.UPDATE_SLIDE, {
                    background: newBackground
                })
            } else {
                const newBackground: SlideBackground = {
                    ...background.value,
                    type: 'gradient',
                    gradientType: background.value.gradientType || 'linear',
                    gradientColor: background.value.gradientColor || [
                        '#fff',
                        '#fff'
                    ],
                    gradientRotate: background.value.gradientRotate || 0
                }
                store.commit(MutationTypes.UPDATE_SLIDE, {
                    background: newBackground
                })
            }
            addHistorySnapshot()
        }

        // 设置背景图片
        const updateBackground = (props: Partial<SlideBackground>) => {
            store.commit(MutationTypes.UPDATE_SLIDE, {
                background: { ...background.value, ...props }
            })
            addHistorySnapshot()
        }

        // 上传背景图片
        const uploadBackgroundImage = async (files: File[]) => {
            const imageFile = files[0]
            if (!imageFile) return
            try {
                const res = await getImageResourceUrl(imageFile)
                updateBackground({ image: res.url, size: res.size })
            } catch (e) {
                logger.error(e)
                message.error('插入图片失败')
            }
        }

        // 应用当前页背景到全部页面
        const applyBackgroundAllSlide = async () => {
            showLoading('应用背景到全部页面，重新生成缩略图中...', 0)
            const newSlides = slides.value.map((slide) => {
                return {
                    ...slide,
                    background: currentSlide.value.background
                }
            })
            store.commit(MutationTypes.SET_SLIDES, newSlides)
            try {
                await pptGeneratePreviewOneByOne(store)
                addHistorySnapshot()
            } catch (e) {
                logger.error('应用背景到全部页面，生成缩略图失败', e)
            } finally {
                closeLoading()
            }
        }

        // 设置主题
        const updateTheme = (themeProps: Partial<SlideTheme>) => {
            store.commit(MutationTypes.SET_THEME, themeProps)
        }

        // 将当前主题应用到全部页面
        const applyThemeAllSlide = async () => {
            showLoading('应用主题到全部页面，重新生成缩略图中...', 0)
            const newSlides: Slide[] = JSON.parse(JSON.stringify(slides.value))
            const {
                themeColor,
                backgroundColor,
                fontColor,
                fontName
            } = theme.value

            for (const slide of newSlides) {
                if (!slide.background || slide.background.type !== 'image') {
                    slide.background = {
                        ...slide.background,
                        type: 'solid',
                        color: backgroundColor
                    }
                }

                const elements = slide.elements
                for (const el of elements) {
                    if (el.type === 'shape') el.fill = themeColor
                    else if (el.type === 'line') el.color = themeColor
                    else if (el.type === 'text') {
                        el.defaultColor = fontColor
                        el.defaultFontName = fontName
                        if (el.fill) el.fill = themeColor
                    } else if (el.type === 'table') {
                        if (el.theme) el.theme.color = themeColor
                        for (const rowCells of el.data) {
                            for (const cell of rowCells) {
                                if (cell.style) {
                                    cell.style.color = fontColor
                                    cell.style.fontname = fontName
                                }
                            }
                        }
                    } else if (el.type === 'chart') {
                        el.themeColor = themeColor
                        el.gridColor = fontColor
                    }
                }
            }
            store.commit(MutationTypes.SET_SLIDES, newSlides)
            try {
                await pptGeneratePreviewOneByOne(store)
                addHistorySnapshot()
            } catch (e) {
                logger.error('应用主题背景到全部，生成缩略图失败', e)
            } finally {
                closeLoading()
            }
        }

        // 是否显示预设主题
        const showPresetThemes = ref(true)
        const togglePresetThemesVisible = () => {
            showPresetThemes.value = !showPresetThemes.value
        }

        // 改变元素竖直位置
        const changeVerticalElements = (oldRatio: number, newRatio: number) => {
            // 上偏移量
            const topOff = (1920 * (newRatio - oldRatio)) / 2
            const newSlides: Slide[] = []
            let newSlideBackground: SlideBackground
            slides.value.forEach((slide: any, index: number) => {
                newSlides[index] = { ...slide }
                newSlideBackground = JSON.parse(
                    JSON.stringify(newSlides[index]?.background)
                )
                // 图片填充时，默认将图片拉伸铺满
                if (newSlideBackground.type === 'image') {
                    newSlideBackground.imageSize = '100% 100%'
                }
                newSlides[index].background = newSlideBackground

                const newElements: PPTElement[] = []
                slide.elements.forEach((el: any) => {
                    const currentEl = JSON.parse(JSON.stringify(el))
                    currentEl.top = currentEl.top + topOff
                    newElements.push(currentEl)
                })
                newSlides[index].elements = newElements
            })
            store.commit(MutationTypes.SET_SLIDES, newSlides)
        }

        // 放大或缩小元素
        const changRatioElements = (oldRatio: number, newRatio: number) => {
            // 主要内容宽度
            const transformWidth = (1920 * newRatio) / oldRatio
            // 左偏移量
            const leftOff = (1920 - transformWidth) / 2
            // 缩放比
            const zoomRatio = newRatio / oldRatio

            const newSlides: Slide[] = []
            let newSlideBackground: SlideBackground
            slides.value.forEach((slide: any, index: number) => {
                newSlides[index] = { ...slide }
                newSlideBackground = JSON.parse(
                    JSON.stringify(newSlides[index]?.background)
                )
                // 图片填充时，默认将图片拉伸铺满
                if (newSlideBackground.type === 'image') {
                    newSlideBackground.imageSize = '100% 100%'
                }
                newSlides[index].background = newSlideBackground

                const newElements: PPTElement[] = []
                slide.elements.forEach((el: any) => {
                    const currentEl = JSON.parse(JSON.stringify(el))
                    currentEl.left = currentEl.left * zoomRatio + leftOff
                    currentEl.top = currentEl.top * zoomRatio
                    currentEl.width = currentEl.width * zoomRatio
                    currentEl.height = currentEl.height * zoomRatio
                    newElements.push(currentEl)
                })
                newSlides[index].elements = newElements
            })
            store.commit(MutationTypes.SET_SLIDES, newSlides)
        }

        // 适应不同画布尺寸
        const updateSlidesRatio = (newViewportRatio: number) => {
            const bestViewportRatio =
                theme.value.bestViewportRatio || viewportRatio.value
            if (bestViewportRatio < newViewportRatio) {
                // 适配最佳比为16：10时(16：10 -> 16：9 -> 4：3)
                // 16：9 -> 4：3的过程，先放大后竖直居中
                if (bestViewportRatio > viewportRatio.value) {
                    changRatioElements(viewportRatio.value, bestViewportRatio)
                    changeVerticalElements(bestViewportRatio, newViewportRatio)
                } else {
                    changeVerticalElements(
                        viewportRatio.value,
                        newViewportRatio
                    )
                }
            } else if (bestViewportRatio > newViewportRatio) {
                // 适配最佳比为16：10时(16: 10 -> 4: 3 -> 16: 9)
                // 4: 3 -> 16: 9的过程，先缩小后恢复竖直位置
                if (bestViewportRatio < viewportRatio.value) {
                    changeVerticalElements(
                        viewportRatio.value,
                        bestViewportRatio
                    )
                    changRatioElements(bestViewportRatio, newViewportRatio)
                } else {
                    changRatioElements(viewportRatio.value, newViewportRatio)
                }
            } else {
                if (viewportRatio.value < bestViewportRatio) {
                    changRatioElements(viewportRatio.value, newViewportRatio)
                } else {
                    changeVerticalElements(
                        viewportRatio.value,
                        newViewportRatio
                    )
                }
            }
        }

        const changeBackgroundImageSize = () => {
            // 图片填充时，默认将图片拉伸铺满
            slides.value.forEach((slide: Slide) => {
                if (slide.background?.type === 'image') {
                    slide.background.imageSize = '100% 100%'
                }
            })
            store.commit(MutationTypes.SET_SLIDES, slides.value)
        }

        const showChangeViewportRatio = (value: number) => {
            viewportChangeModalVisible.value = true
            newViewportRatio.value = value
        }

        // 设置画布尺寸（宽高比例）
        const updateViewportRatio = (value: number) => {
            store.commit(MutationTypes.SET_VIEWPORT_RATIO, value)
            setTimeout(async () => {
                try {
                    await pptGeneratePreviewOneByOne(store)
                    addHistorySnapshot()
                } catch (e) {
                    logger.error('(画布尺寸调整)-预览图生成失败', e)
                } finally {
                    closeLoading()
                }
            }, 0)
        }
        // 画布调整不需要适应
        const handleChangeViewportRatioNoFit = () => {
            showLoading('画布尺寸调整中,请勿关闭窗口...', 0)
            changeBackgroundImageSize()
            updateViewportRatio(newViewportRatio.value)
            viewportChangeModalVisible.value = false
        }
        // 画布调整需要适应
        const handleChangeViewportRatioFit = () => {
            showLoading('画布尺寸调整中,请勿关闭窗口...', 0)
            updateSlidesRatio(newViewportRatio.value)
            updateViewportRatio(newViewportRatio.value)
            viewportChangeModalVisible.value = false
        }

        const handleCancelChangeViewportRatio = () => {
            viewportRatio.value = store.state.viewportRatio
            newViewportRatio.value = store.state.viewportRatio
        }
        const updateTextFontName = (value: string) => {
            updateTheme({ fontName: value })
        }

        return {
            availableFonts,
            background,
            updateBackgroundType,
            updateBackground,
            uploadBackgroundImage,
            applyBackgroundAllSlide,
            themes,
            theme,
            webFonts,
            updateTheme,
            applyThemeAllSlide,
            viewportRatio,
            updateViewportRatio,
            showPresetThemes,
            togglePresetThemesVisible,
            showChangeViewportRatio,
            viewportRatioMap,
            newViewportRatio,
            viewportChangeModalVisible,
            updateTextFontName,
            handleChangeViewportRatioNoFit,
            handleChangeViewportRatioFit,
            handleCancelChangeViewportRatio
        }
    }
})
